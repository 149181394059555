<script>
  import { IconNewTab } from '@/icons'
  import activityCenterMixin from '../mixins/activityCenterMixin'

  export default {
    name: 'ProductIssue',

    mixins: [activityCenterMixin],

    props: {
      issue: {
        type: Object,
        required: true
      }
    },

    components: {
      IconNewTab
    },

    methods: {
      searchByProductHander(searchStr) {
        this.productIssuesSearchStr = searchStr
        this.fetchActivitiesHandler()
      }
    }
  }
</script>

<template>
  <tr>
    <td>
      {{ issue.date }}
      <div class="text-small">{{ issue.time }}</div>
    </td>
    <td>
      <span class="pointer" @click="searchByProductHander(issue.title)">
        {{ issue.title }}
        <div class="text-small">{{ issue.subtitle }}</div>
      </span>
    </td>
    <td>
      <table class="w-100" v-if="issue.data">
        <tr @click="searchByProductHander(issue.data.name)" class="pointer">
          <td style="" class="product-image-column py-0">
            <img :src="issue.data.image" v-if="issue.data.image" style="width: 32px; padding: 2px; border: 1px solid #E7E7E7;">
            <img src="@/assets/images/placeholder.png" v-else alt="" style="width: 32px;">
          </td>
          <td class="py-0">
            <a href="javascript:void(0)" class="link">{{ issue.data.name }}</a>
            <div class="text-small">{{ issue.data.store_name }}</div>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <template v-if="issue.details">
        {{ issue.details.line_1 }}
        <div class="text-small">{{ issue.details.line_2 }}</div>
      </template>
    </td>
    <td>
      <a :href="issue.link" class="link link-underline" target="_blank" v-if="issue.link">
        How to fix this
        <IconNewTab />
      </a>
    </td>
    <td class="text-right">
      <v-btn elevation="0" class="btn btn-border" :class="{ 'disabled': loading }" @click="dismissIssueHandler(issue.id)">Dismiss</v-btn>
    </td>
  </tr>
</template>
