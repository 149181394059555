<script>
import { IconNewTab } from '@/icons'

export default {
  name: 'EmptyIssues',

  props: {
    colspan: {
      type: String,
      required: true
    }
  },

  components: {
    IconNewTab
  }
}
</script>

<template>
  <tbody>
    <tr class="no-hover">
      <td :colspan="colspan" class="text-center empty-table-message">
        <p><strong>You have no logs to review at this time.</strong></p>
        <p>Syncio will record important events and logs here.</p>
        <p>
          You can set up email alerts for important updates in
          <router-link to="/settings/notification-settings" class="link link-underline">Notification Settings</router-link>.
        </p>
        <p class="mb-0 v2 pb-0">
          <a href="https://help.syncio.co/en/articles/5596711-activity-center" target="_blank" class="link link-underline">
            Learn more about the Activity Center
            <IconNewTab />
          </a>
        </p>
      </td>
    </tr>
  </tbody>
</template>

<style scoped>
p, .link {
  font-size: 16px !important;
}

p + p {
  margin-top: 15px;
}
</style>
