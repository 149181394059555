<script>
  import { CloseCircle } from '@/icons'
  import activityCenterMixin from '../mixins/activityCenterMixin'
  import AppliedFilters from '@/views/payouts/components/AppliedFilters.vue'
  import EmptyIssues from '../EmptyIssues.vue'
  import Filters from './Filters.vue'
  import Pagination from '@/components/UI/Pagination.vue'
  import ProductIssue from './ProductIssue.vue'

  export default {
    name: 'ProductIssuesTable',

    mixins: [activityCenterMixin],

    components: {
      AppliedFilters,
      CloseCircle,
      EmptyIssues,
      Filters,
      ProductIssue,
      Pagination
    },

    methods: {
      clearFiltersHandler() {
        this.productIssuesFilters = { store_domain: 'all stores', event: 'all_events' }
        this.productIssuesPartnerStore = null
        this.productIssuesSearchStr = ''
        this.fetchActivitiesHandler()
      },

      updateCurrentPageHandler(page) {
        this.productIssuesCurrentPage = page
        this.fetchActivitiesHandler(this.productIssuesCurrentPage)
      }
    }
  }
</script>

<template>
  <article>
    <Filters />

    <!-- Applied Filters -->
    <section class="applied-filters-row d-flex align-center">
      <aside class="d-flex align-center left-action">
        <div class="applied-filters-listing d-flex align-center">
          <strong>Filters:</strong>
          <AppliedFilters :appliedFilters="productIssuesFilters" />
        </div>
      </aside>
      <div class="right-action-container">
        <button class="btn-icon d-flex align-center clear-filters-btn" @click="clearFiltersHandler">
          <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
        </button>
      </div>
    </section>

    <section class="table">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th style="width: 10%;">Date (AEST)</th>
              <th style="width: 25%;">Issue and suggested action</th>
              <th style="width: 25%;">Product</th>
              <th style="width: 15%;">Detail</th>
              <th style="width: 15%;"></th>
              <th style="width: 10%;" class="text-right">Actions</th>
            </tr>
          </thead>
          <EmptyIssues v-if="productIssues && productIssues.length === 0" :colspan="'6'" />
          <tbody v-else>
            <ProductIssue v-for="issue in productIssues" :key="issue.id" :issue="issue" />
          </tbody>
        </template>
      </v-simple-table>

      <!-- Pagination -->
      <Pagination :pagination="productIssuesPagination" @updateCurrentPage="updateCurrentPageHandler" />
    </section>
  </article>
</template>
