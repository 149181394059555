<script>
  import { ChevronDown, IconFilter, PlusCircle, SearchIcon, StoreLarge } from '@/icons'
  import { mapState } from 'vuex'
  import activityCenterMixin from '../mixins/activityCenterMixin'
  import SelectFilter from '@/views/payouts/components/SelectFilter.vue'

  export default {
    name: 'Filters',

    data() {
      return {
        connectedStores: [],
        isEventFilterVisible: false,
        isSearchingStore: false,
        isStoreFilterVisible: false,
        storeSearchTerm: '',
      }
    },

    mixins: [activityCenterMixin],

    components: {
      ChevronDown,
      IconFilter,
      PlusCircle,
      SearchIcon,
      SelectFilter,
      StoreLarge
    },

    computed: {
      ...mapState('shop', ['connectedShops']),
    },

    methods: {
      swapItems() {
        this.isSearchingStore = true
        this.connectedStores = []
        document.querySelector(".search-input").focus();
        this.connectedStores = [...this.connectedStores, ...this.connectedShops]
      },

      onClickOutsideStoreFilter() {
        this.isStoreFilterVisible = false
      },

      onClickOutsideEventFilter() {
        this.isEventFilterVisible = false
      },

      onSearchStoreHandler(event) {
        this.connectedStores = this.connectedShops.filter(store => store.store_domain.toLowerCase().includes(event.target.value.toLowerCase()))
      },

      closeFilters() {
        this.isEventFilterVisible = this.isSearchingStore = this.isStoreFilterVisible = false
      },

      async applyFilter({ key, value }) {
        this.orderIssuesFilters[key] = value
        await this.fetchActivitiesHandler()
        this.closeFilters()
      },

      async applyStoreFilter({ id, store_domain }) {
        this.orderIssuesPartnerStore = { id, store_domain }
        this.orderIssuesFilters.store_domain = store_domain
        await this.fetchActivitiesHandler()
        this.closeFilters()
      }
    }
  }
</script>

<template>
  <section class="filters-listing">
    <ul class="d-flex pa-0">
      <li class="filters-listing__filter d-flex" data-filter="search">
        <div class="input-prepend d-flex" @click="fetchActivitiesHandler">
          <SearchIcon />
        </div>
        <input type="text" v-model="orderIssuesSearchStr" class="input-field prepend-icon" placeholder="Search by order number or order ID" @keyup.enter="fetchActivitiesHandler" />
      </li>
      <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
        <SelectFilter>
          <template #iconBefore>
            <StoreLarge />
          </template>
          <template #label>
            <div class="label" @click="swapItems">
              <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
              <span v-show="!isStoreFilterVisible">{{ (orderIssuesPartnerStore && orderIssuesPartnerStore.store_domain) || 'All Stores' }}</span>
            </div>
          </template>
          <template #iconAfter>
            <div class="icon-right absolute">
              <ChevronDown />
            </div>
          </template>
          <template #options>
            <transition name="slide-x-reverse-transition">
              <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                <li v-for="shop in connectedStores" :key="shop.id" @click="applyStoreFilter(shop)" :class="{ 'active': orderIssuesPartnerStore && orderIssuesPartnerStore.store_domain === shop.store_domain }">
                  {{ shop.store_domain }}
                </li>
                <li class="not-found" key="store-not-found" v-if="connectedStores.length === 0">Store not found</li>
              </ul>
            </transition>
          </template>
        </SelectFilter>
      </li>
      <li class="filters-listing__filter" data-filter="events" @click="isEventFilterVisible = true" v-click-outside="onClickOutsideEventFilter">
        <SelectFilter>
          <template #iconBefore>
            <IconFilter />
          </template>
          <template #label>
            <div class="label">Filters:
              <span>{{ orderIssuesFilters.event | removeUnderscore }}</span>
            </div>
          </template>
          <template #iconAfter>
            <div class="icon-right absolute" style="height: 21px;">
              <PlusCircle />
            </div>
          </template>
          <template #options>
            <transition name="slide-x-reverse-transition">
              <ul class="filter-options" v-if="isEventFilterVisible">
                <li :class="{ 'active': orderIssuesFilters.event === event.value }" v-for="event in orderIssuesEventOptions" :key="event.value" @click.stop="applyFilter(event)">
                  {{ event.label }}
                </li>
              </ul>
            </transition>
          </template>
        </SelectFilter>
      </li>
    </ul>
  </section>
</template>
