import { createHelpers } from 'vuex-map-fields'
var Nanobar = require('../../../../node_modules/nanobar/nanobar')
const nanobar = new Nanobar()

const { mapFields } = createHelpers({
  getterType: 'activityCenter/getField',
  mutationType: 'activityCenter/updateField'
})

export default {
  data() {
    return {
      filters: [],
      page: null,
      partnerStore: { id: '', store_domain: '' },
      searchStr: null,
    }
  },

  computed: {
    ...mapFields([
      'generalUpdates',
      'generalUpdatesCurrentPage',
      'generalUpdatesEventOptions',
      'generalUpdatesFilters',
      'generalUpdatesPagination',
      'generalUpdatesPartnerStore',
      'generalUpdatesSearchStr',

      'loading',

      'orderIssues',
      'orderIssuesCurrentPage',
      'orderIssuesEventOptions',
      'orderIssuesFilters',
      'orderIssuesPagination',
      'orderIssuesPartnerStore',
      'orderIssuesSearchStr',

      'productIssues',
      'productIssuesCurrentPage',
      'productIssuesEventOptions',
      'productIssuesFilters',
      'productIssuesPagination',
      'productIssuesPartnerStore',
      'productIssuesSearchStr',

      'selectedTab',
      'tabs'
    ])
  },

  methods: {
    randomNumber(min, max){
      const r = Math.random()*(max-min) + min
      return Math.floor(r)
    },

    setFilters(filters) {
      let filtersArray = []
      const { event } = filters
      if(event !== 'all_events') {
        filtersArray.push({ event })
      }
      return filtersArray
    },

    generateFilters() {
      if(this.selectedTab === 'product') {
        this.searchStr = encodeURIComponent(this.productIssuesSearchStr)
        this.filters = this.setFilters(this.productIssuesFilters)
        this.partnerStore = this.productIssuesPartnerStore
      } else if(this.selectedTab === 'order') {
        this.searchStr = encodeURIComponent(this.orderIssuesSearchStr)
        this.filters = this.setFilters(this.orderIssuesFilters)
        this.partnerStore = this.orderIssuesPartnerStore
      } else if(this.selectedTab === 'general') {
        this.searchStr = encodeURIComponent(this.generalUpdatesSearchStr)
        this.filters = this.setFilters(this.generalUpdatesFilters)
        this.partnerStore = this.generalUpdatesPartnerStore
      }
    },

    async fetchActivitiesHandler(page) {
      nanobar.go(this.randomNumber(20, 90))
      this.generateFilters()
      await this.$store.dispatch('activityCenter/fetchActivities', {
        filters: this.filters,
        group: this.selectedTab,
        page: page,
        partner_store_id: this.partnerStore && this.partnerStore.id,
        search_str: this.searchStr
      })
      nanobar.go(100)
    },

    async dismissIssueHandler(id) {
      this.loading = true
      await this.$store.dispatch('activityCenter/dismissIssue', {
        activityId: id
      })

      await this.fetchActivitiesHandler()
      this.loading = false
    },
  }
}
