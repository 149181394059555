<script>
import payoutsMixin from '@/views/payouts/mixin/payoutsMixin';

export default {
  name: 'AppliedFilters',

  mixins: [payoutsMixin],

  props: ['appliedFilters', 'selectedPayoutStore'],

  computed: {
    availableFilters() {
      let filters = {};
      for(const property in this.appliedFilters) {
        if(this.appliedFilters[property] !== "") {
          filters = { ...filters, [property]: this.appliedFilters[property] }
        }
      }
      return filters;
    },
  }
}
</script>

<template>
  <ul class="applied-filters pa-0 d-flex">
    <transition-group name="scroll-x-transition">
      <li v-for="(filter, key) in availableFilters" :key="'key: ' + key" class="d-inline-flex align-center">
        <span v-if="key === 'target_store'" class="text-tranform-none">
          {{ selectedPayoutStore.text || selectedPayoutStore.store_domain }}
        </span>
        <span v-else-if="filter === 'sku'" class="text-uppercase sku">
          {{ filter }}
        </span>
        <span v-else :data-filter="filter">
          {{ filter | removeUnderscore }}
        </span>
      </li>
    </transition-group>
  </ul>
</template>
