<script>
  import { IconNewTab } from '@/icons'

  export default {
    name: 'GeneralUpdate',

    props: {
      update: {
        type: Object,
        required: true
      }
    },

    components: {
      IconNewTab
    }
  }
</script>

<template>
  <tr>
    <td>
      {{ update.date }}
      <div class="text-small">{{ update.time }}</div>
    </td>
    <td>
      {{ update.title }} <span class="text-lowercase">{{ update.subtitle }}</span>
    </td>
    <td>
      {{ update.data.store_name ? update.data.store_name : '-' }}
    </td>
    <td>
      {{ update.details.line_1 }}
    </td>
    <td>
      <a :href="update.link" v-if="update.link" class="link link-underline" target="_blank">
        Learn more
        <IconNewTab />
      </a>
    </td>
  </tr>
</template>
