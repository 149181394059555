<script>
  import { CloseCircle } from '@/icons'
  import activityCenterMixin from '../mixins/activityCenterMixin'
  import AppliedFilters from '@/views/payouts/components/AppliedFilters.vue'
  import EmptyIssues from '../EmptyIssues.vue'
  import Filters from './Filters.vue'
  import GeneralUpdate from './GeneralUpdate.vue'
  import Pagination from '@/components/UI/Pagination.vue'

  export default {
    name: 'GeneralUpdatesTable',

    mixins: [activityCenterMixin],

    components: {
      AppliedFilters,
      CloseCircle,
      EmptyIssues,
      Filters,
      GeneralUpdate,
      Pagination
    },

    methods: {
      clearFiltersHandler() {
        this.generalUpdatesFilters = { store_domain: 'all stores', event: 'all_events' }
        this.generalUpdatesPartnerStore = null
        this.generalUpdatesSearchStr = ''
        this.fetchActivitiesHandler()
      },

      updateCurrentPageHandler(page) {
        this.generalUpdatesCurrentPage = page
        this.fetchActivitiesHandler(this.generalUpdatesCurrentPage)
      }
    }
  }
</script>

<template>
  <article>
    <Filters />

    <!-- Applied Filters -->
    <section class="applied-filters-row d-flex align-center">
      <aside class="d-flex align-center left-action">
        <div class="applied-filters-listing d-flex align-center">
          <strong>Filters:</strong>
          <AppliedFilters :appliedFilters="generalUpdatesFilters" />
        </div>
      </aside>
      <div class="right-action-container">
        <button class="btn-icon d-flex align-center clear-filters-btn" @click="clearFiltersHandler">
          <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
        </button>
      </div>
    </section>

    <section class="table">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th style="width: 10%;">Date (AEST)</th>
              <th style="width: 20%;">Log</th>
              <th style="width: 20%;">Store</th>
              <th style="width: 40%;">Detail</th>
              <th style="width: 10%;" class="text-right"></th>
            </tr>
          </thead>
          <EmptyIssues v-if="generalUpdates && generalUpdates.length === 0" :colspan="'5'" />
          <tbody>
            <GeneralUpdate v-for="update in generalUpdates" :update="update" :key="update.id" />
          </tbody>
        </template>
      </v-simple-table>

      <!-- Pagination -->
      <Pagination :pagination="generalUpdatesPagination" @updateCurrentPage="updateCurrentPageHandler" />
    </section>
  </article>
</template>
