<script>
  import { IconNewTab } from '@/icons'
  import activityCenterMixin from '../mixins/activityCenterMixin'

  export default {
    name: 'OrderIssue',

    mixins: [activityCenterMixin],

    props: {
      issue: {
        type: Object,
        required: true
      }
    },

    components: {
      IconNewTab
    },

    methods: {
      searchByOrderHander(searchStr) {
        this.orderIssuesSearchStr = searchStr.replace('#', '')
        this.fetchActivitiesHandler()
      }
    }
  }
</script>

<template>
  <tr>
    <td>
      {{ issue.date }}
      <div class="text-small">{{ issue.time }}</div>
    </td>
    <td>
      {{ issue.title }}
      <div class="text-small">{{ issue.subtitle }}</div>
    </td>
    <td>
      <template v-if="issue.data">
        <a href="javascript:void(0)" class="link" @click="searchByOrderHander(issue.data.name)">{{ issue.data.name }}</a>
        <div class="text-small">{{ issue.data.store_name }}</div>
      </template>
    </td>
    <td>
      <template v-if="issue.details">
        {{ issue.details.line_1 }}
        <div class="text-small">{{ issue.details.line_2 }}</div>
      </template>
    </td>
    <td>
      <a :href="issue.link" class="link link-underline" target="_blank" v-if="issue.link">
        How to fix this
        <IconNewTab />
      </a>
    </td>
    <td class="text-right">
      <v-btn elevation="0" class="btn btn-border" :class="{ 'disabled': loading }" @click="dismissIssueHandler(issue.id)">Dismiss</v-btn>
    </td>
  </tr>
</template>
