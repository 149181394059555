<script>
import { LOAD_CURRENT_STORE_INVENTORIES } from '@/store/actions.type'
import { mapState } from 'vuex'
import activityCenterMixin from './mixins/activityCenterMixin'
import GeneralUpdatesTable from './general/GeneralUpdatesTable.vue'
import OrderIssuesTable from './order/OrderIssuesTable.vue'
import PageHeader from '@/views/payouts/components/PageHeader.vue'
import ProductIssuesTable from './product/ProductIssuesTable.vue'
import Tabs from '@/components/UI/Tabs.vue'

export default {
  name: 'ActivityCenter',

  mixins: [activityCenterMixin],

  computed: {
    ...mapState('shop', [ 'currentShop' ]),
  },

  components: {
    GeneralUpdatesTable,
    OrderIssuesTable,
    PageHeader,
    ProductIssuesTable,
    Tabs
  },

  async created () {
    await this.$store.dispatch(`shop/init`).then(() => {
      if (this.currentShop != null) {
        this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
      }
    });

    if(this.currentShop && this.currentShop.type !== 'source' && this.currentShop.platform !== 'woocommerce') {
      this.fetchActivitiesHandler()
    } else {
      this.$router.push({ name: 'DashboardPage' });
    }
  },

  methods: {
    changeTabHandler(tab) {
      this.selectedTab = tab.value
      if(this.selectedTab === 'product') this.fetchActivitiesHandler(this.productIssuesCurrentPage)
      else if(this.selectedTab === 'order') this.fetchActivitiesHandler(this.orderIssuesCurrentPage)
      else if(this.selectedTab === 'general') this.fetchActivitiesHandler(this.generalUpdatesCurrentPage)
    }
  }
}
</script>

<template>
  <section class="v2 page activity-center">
    <!-- Page Header -->
    <PageHeader pageDescription="See what's happening with your syncs, find and fix issues.">
      <template #title>
        Activity Center
      </template>
    </PageHeader>

    <!-- Tabs -->
    <Tabs :tabs="tabs" :selectedTab="selectedTab" @changeTab="changeTabHandler" />

    <!-- Issues -->
    <transition name="slide-x-reverse-transition" mode="out-in" duration="200">
      <KeepAlive>
        <ProductIssuesTable v-if="selectedTab === 'product'" />
        <OrderIssuesTable v-if="selectedTab === 'order'" />
        <GeneralUpdatesTable v-if="selectedTab === 'general'" />
      </KeepAlive>
    </transition>
  </section>
</template>
